import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { Banner, Article, Head, Layout } from "../components"

export const ArticlePageTemplate = ({ title, content, image }) => (
  <>
    <Banner interior title={title} subheading="Actualités" />
    <Article
      title={title}
      image={
        image && image.childImageSharp ? image.childImageSharp.fluid : null
      }
      content={content}
    ></Article>
  </>
)

ArticlePageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.object,
}

const ArticlePage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout>
      <Head title={frontmatter.title} />
      <ArticlePageTemplate
        title={frontmatter.title}
        content={html}
        image={frontmatter.image}
      />
    </Layout>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.object,
    }),
  }),
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticlePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "article" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 570, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
